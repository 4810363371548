import React, { useState } from "react";
import { makeStyles, Slider } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Cursor } from "components/icons/components/Cursor";
import { Ellipse } from "components/icons/components/Ellipse";
import { Saving } from "components/Saving";
import { MAP_TYPES } from "pages/constants";
import { calculateCircleRadius, calculateMinCircleRadius } from "components/audience-map/utils";
import cn from "classnames";
import { styles } from "./styles";

const zoomValues = {
  plus: "+",
  minus: "-",
};

const radiusOptions = [1, 2, 5, 10];

const useStyles = makeStyles(() => styles);

export const EDDMToolbar = ({
  radius,
  setRadius,
  isSaving,
  drawingMode,
  setDrawingMode,
  setShouldAutoSave,
  circleSelectorRadius,
  setCircleSelectorRadius,
}) => {
  const [openSlider, setOpenSlider] = useState(false);
  const classes = useStyles();

  const handleZoomChange = (value) => {
    // setDrawingMode(null);
    const currentRadiusOption = radiusOptions.findIndex((r) => r === radius);
    if (value === zoomValues.plus && radius < 10) {
      setShouldAutoSave(true);
      setRadius(radiusOptions[currentRadiusOption + 1]);
    } else if (value === zoomValues.minus && radius > 1) {
      setShouldAutoSave(true);
      setRadius(radiusOptions[currentRadiusOption - 1]);
    }
  };

  const increaseZoomDisabled = radius === 10;
  const decreaseZoomDisabled = radius === 1;
  const cursorSelected = drawingMode === null;
  const circleSelected = drawingMode === MAP_TYPES.CIRCLE;

  return (
    <div className={classes.toolbarContainer}>
      <div className={cn(classes.toolbarSection, classes.zoomSectionContainer)}>
        <span>Map Radius</span>
        <div className={classes.zoomSection}>
          <div className={classes.zoomButtons}>
            <div className={cn(classes.zoomButton, classes.zoomPlus)}>
              <AddIcon
                onClick={() => handleZoomChange(zoomValues.plus)}
                className={cn({ [classes.disabled]: increaseZoomDisabled })}
                disabled={increaseZoomDisabled}
              />
            </div>
            <div className={cn(classes.zoomButton, classes.zoomMinus)}>
              <RemoveIcon
                onClick={() => handleZoomChange(zoomValues.minus)}
                className={cn({ [classes.disabled]: decreaseZoomDisabled })}
                disabled={decreaseZoomDisabled}
              />
            </div>
          </div>
          <span>{radius} miles</span>
        </div>
      </div>

      <div className={classes.separator} />

      <div className={cn(classes.toolbarSection, classes.selectorContainer)}>
        <span>Selection</span>
        <div className={classes.selectionSection}>
          <div
            className={cn(classes.selectionButton, { [classes.selectedButton]: drawingMode === null })}
            onClick={() => setDrawingMode(null)}
          >
            <Cursor stroke={cursorSelected ? "white" : "#1A2547"} /> <span>Route</span>
          </div>
          <div className={classes.buttonsSeparator} />
          <div
            className={cn(classes.selectionButton, { [classes.selectedButton]: drawingMode === MAP_TYPES.CIRCLE })}
            onClick={() => setDrawingMode(MAP_TYPES.CIRCLE)}
          >
            <Ellipse stroke={circleSelected ? "white" : "#1A2547"} />
            <span>Area</span>
          </div>
        </div>
      </div>
      {circleSelected && (
        <div className={classes.toolbarSection}>
          <span>Selector size</span>
          {openSlider && (
            <div className={classes.sliderContainer}>
              <Slider
                value={circleSelectorRadius}
                min={calculateMinCircleRadius(radius)}
                step={0.25}
                max={radius}
                onChange={(e, value) => {
                  setCircleSelectorRadius(value);
                }}
              />
            </div>
          )}
          <div
            className={cn(classes.selectionSection, classes.cursorPointer)}
            onClick={() => setOpenSlider(!openSlider)}
          >
            <span className={classes.sizeValue}>{circleSelectorRadius}</span>
            <div className={classes.buttonsSeparator} />
            {openSlider ? (
              <ExpandLessIcon className={classes.expandIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandIcon} />
            )}
          </div>
        </div>
      )}

      <div className={classes.savingContainer}>
        <Saving saving={isSaving} hideLabels />
      </div>
    </div>
  );
};
