import { useContext, useEffect, useMemo, useRef, useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import cn from "classnames";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import { SnackBarContext } from "components/snack-bar";
import { debounce } from "lodash";
import { Box, makeStyles } from "@material-ui/core";
import { useJsApiLoader } from "@react-google-maps/api";
import { PlaceAutocomplete } from "components/place-autocomplete";
import { CustomTooltipContent } from "components/tooltip-content";
import { LightTooltip } from "components/light-tooltip";
import Header from "components/header";
import BounceLoader from "components/loaders/bounce-loader";
import { IfElse } from "components/logic";
import { ProgressBarContext } from "components/progress-bar";
import { checkIsNeedUpdateLastActiveStepState } from "utils";
import { Saving } from "components/Saving";
import { AudienceMapEDDM } from "components/audience-map/components/AudienceMapEDDM";
import CONFIG from "config/config";
import { DM_ROUTING } from "config/routing";
import { getSelectedRoutesFromDistributionLocations } from "components/audience-map/components/AudienceMapEDDM/utils";
import CustomSelect from "components/custom-select";
import { EDDMEmptyState, EDDMToolbar } from "components/audience-map/components";
import { calculateCircleRadius } from "components/audience-map/utils";
import useExitPrompt from "Hooks/useExitPrompt";
import { apiClient } from "../../../module/api";
import { NextButton } from "../../../components/next-button";
import {
  CAMPAIGN_INTERNAL_STATUSES,
  EDDM_NAVIGATION,
  radiusToZoom,
  COUPON_CODE_ERROR_MESSAGE,
  MAP_TYPES,
} from "../../constants";
import {
  createQuote,
  updateCampaignExtraData,
  updateSelfServeCampaignData,
  updateCampaign as updateCampaignDB,
} from "../../../graphQL";
import BlockingOverlay from "../../../components/BlockingOverlay";
import { styles } from "./styles.js";
import { useStore } from "../../../store";
import { CAMPAIGN_ENUM_TYPES } from "../../../shared/constants";
import "./styles.css";

const libraries = ["geometry", "places", "drawing"];
const useStyles = makeStyles(() => styles);
const radiusOptions = [1, 2, 5, 10].map((v) => ({
  value: v,
  label: `${v} miles`,
}));

export const SegmentsV2 = () => {
  const classes = useStyles();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONFIG.GOOGLE_API_KEY,
    libraries: libraries,
    language: "en",
  });

  const history = useHistory();
  const [, setShowExitPrompt] = useExitPrompt(false);
  const [radius, setRadius] = useState(5);
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  const [drawingMode, setDrawingMode] = useState(MAP_TYPES.CIRCLE);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [circleSelectorRadius, setCircleSelectorRadius] = useState(calculateCircleRadius(radius)); // radius * 10% * metersPerMiles

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {
    costsCalculationData,
    country,
    campaign: {
      channel,
      campaignName,
      // flyerType,
      // flyerWeight,
      // campaignDuration,
      internalStatus,
      isSubmitted,
      id: campaignId,
      lastActiveStep,
      stripeCouponCode,
    },
    errors: { campaignNameDuplicates },
    user: { accessDenied = true, id: userId, isVerified },
    client: { id: clientId, name: clientName },
    map: { center, loading, loadingPercentage, visiblePostCodes = [], shouldShowDefaultPin },
    distributionLocations,
    setDistributionLocations,
    setFocusedLocation,
    updateCampaign,
    updateMap,
    updateErrors,
  } = useStore();
  const fromHome = useMemo(() => lastActiveStep === "home", [lastActiveStep]);

  const { totalCost, tax } = costsCalculationData?.detailedCost || {};

  // useContext

  const runSnackBar = useContext(SnackBarContext);
  const runProgressBar = useContext(ProgressBarContext);

  // const runDialogModal = useContext(DialogModalContext);

  // useState
  const [selectedRoutes, setSelectedRoutes] = useState(
    getSelectedRoutesFromDistributionLocations(distributionLocations)
  );
  const [shouldAutoSave, setShouldAutoSave] = useState(false);

  const [error, setError] = useState(true);
  // const [gTagEvents, setgTagEvents] = useState({ ss_campaign_aud_area: false, ss_campaign_aud_city: false });
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [validatingCampaignName, setValidatingCampaignName] = useState(false);

  // useRef
  const clickSaveButtonRef = useRef(false);

  // useMemo
  const isNextActive = useMemo(() => {
    return costsCalculationData.flyersAmount && costsCalculationData.flyersAmount > 1000;
  }, [costsCalculationData.flyersAmount, isSubmitted]);

  const TooltipContent = useMemo(() => {
    if (!isNextActive) {
      return (
        <CustomTooltipContent
          title="Not enough addresses!"
          content="To proceed, please select at least 1000 addresses."
        />
      );
    }

    return "";
  }, [isNextActive]);

  // useEffect

  useEffect(() => {
    if (isLoaded && internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT && stripeCouponCode) {
      getStripeCouponData({ stripeCouponCode });
    }
  }, [isLoaded, stripeCouponCode, internalStatus]);

  useEffect(() => {
    if (campaignNameDuplicates) {
      setError("Duplicate campaign name. Please choose another.");
      return;
    }
  }, [campaignNameDuplicates]);

  useEffect(() => {
    if (distributionLocations && distributionLocations.length) {
      const focusedLocation = distributionLocations.find((location) => location.isFocused);
      if (focusedLocation) {
        setRadius(focusedLocation.radius);
        updateMap({ center: { lat: focusedLocation.lat, lng: focusedLocation.lng, zoom: radiusToZoom[radius] } });
      }
    }
  }, []);

  const saveDataToDB = debounce(() => {
    saveCampaign(true);
    setShouldAutoSave(false);
  }, 1000);

  useEffect(() => {
    if (distributionLocations && shouldAutoSave) {
      saveDataToDB(distributionLocations);
    }
    return () => {
      saveDataToDB.cancel();
    };
  }, [distributionLocations]);

  // useCallback
  const saveCampaign = async (isAutoSave) => {
    setIsSaving(true);
    if (!isSubmitted && distributionLocations) {
      const payloadCampaignExtraData = {
        campaignId,
        totalCosts: totalCost,
        flyersCount: costsCalculationData?.flyersAmount || 0,
        taxes: tax,
      };

      const isNeedUpdateLastActiveStep = checkIsNeedUpdateLastActiveStepState({
        stateLastActiveStep: lastActiveStep,
        newLastActiveStep: "segments",
        isDM: true,
      });

      if (isNeedUpdateLastActiveStep && !isAutoSave) {
        payloadCampaignExtraData.lastActiveStep = "segments";
      }

      await updateCampaignExtraData(payloadCampaignExtraData);
      await updateSelfServeCampaignData({
        campaignId: campaignId,
        distributionLocations: distributionLocations,
      });
      if (isNeedUpdateLastActiveStep && !isAutoSave) {
        updateCampaign({ lastActiveStep: "segments" });
      }
      await setQuoteNumber(campaignId);
      if (!isAutoSave) {
        nextBtnClicked(campaignId);
      }
    }
    setIsSaving(false);
  };

  const setQuoteNumber = async (campaignId) => {
    const quote = await createQuote(campaignId);
    updateCampaign({ quote });
  };

  const nextBtnClicked = (id) => {
    return !internalStatus || internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT
      ? history.push(generatePath(DM_ROUTING.DM_UPLOAD_FLYER, { campaignId: id, clientId }))
      : history.push(generatePath(DM_ROUTING.DM_CAMPAIGN_DETAILS, { campaignId: id, clientId }));
  };

  const headerActions = {
    HOME: {
      action: () => {
        console.log("redirect home");
      },
    },
    NEXT: {
      isActive: true,
      action: async () => {
        if (isSubmitted) {
          runProgressBar(60);
          nextBtnClicked(campaignId);
          runProgressBar(80);
          runProgressBar(-1);
        } else {
          clickSaveButtonRef.current = false;
          await saveCampaign();
        }
      },
      hintPlacement: "bottom",
      hint: "",
    },
  };

  const getStripeCouponData = async ({ stripeCouponCode }) => {
    if (stripeCouponCode) {
      const code = stripeCouponCode.trim();
      try {
        const res = await apiClient.getPromotionCode({
          promotionCode: code,
          countryCode: country?.code,
        });
        if (res.status !== 200) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }
        const { promotionCodes: responseData } = await res.json();
        if (!responseData) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const { data } = responseData;
        if (!data || !data[0] || code !== data[0].code) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const {
          id: promoId,
          coupon: couponObject,
          max_redemptions: max_redemptions_promotion_code,
          times_redeemed: times_redeemed_promotion_code,
          restrictions,
        } = data[0];

        if (!couponObject || !couponObject.id || !couponObject.percent_off) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["NO_PROMO_EXIST"]);
        }
        if (
          max_redemptions_promotion_code &&
          times_redeemed_promotion_code &&
          max_redemptions_promotion_code <= times_redeemed_promotion_code
        ) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["EXCEED_USE_PROMO"]);
        }
        if (!max_redemptions_promotion_code && !times_redeemed_promotion_code && !restrictions) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const { max_redemptions: max_redemptions_coupon, times_redeemed: times_redeemed_coupon } = couponObject;
        if (max_redemptions_coupon && times_redeemed_coupon && max_redemptions_coupon <= times_redeemed_coupon) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["EXCEED_USE_COUPON"]);
        }
        if (!max_redemptions_coupon && !times_redeemed_coupon && !restrictions) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        if (restrictions) {
          const { first_time_transaction } = restrictions;
          if (first_time_transaction) {
            // TO DO: SAVE THIS ON STRIPE CUSTOMER METADATA OR ON THE OPPIZI USER
            const didReedemed = !!localStorage.getItem(`first_time_transaction_${promoId}`);
            if (didReedemed) {
              throw new Error(COUPON_CODE_ERROR_MESSAGE["ONLY_FIRST"]);
            }
          }
        }

        const percent_off = couponObject.percent_off;

        runSnackBar({
          type: "success",
          vertical: "top",
          msg: `Your ${percent_off}% discount will be applied at checkout.`,
        });
      } catch (err) {
        setCouponErrorMessage(err.message);
      }
    }
    return false;
  };

  const handleAutocompleteChanged = (newMarker) => {
    if (newMarker) {
      const lat = newMarker?.geometry?.location?.lat();
      const lng = newMarker?.geometry?.location?.lng();
      const locationExists = !!distributionLocations.find(
        (location) =>
          location.lat === newMarker?.geometry?.location?.lat() && location.lng === newMarker?.geometry?.location?.lng()
      );
      setShouldAutoSave(true);
      if (!locationExists) {
        setDistributionLocations([
          ...distributionLocations.map((l) => ({ ...l, isFocused: false })),
          {
            name: newMarker.formattedAddress,
            lat,
            lng,
            radius: radius,
            isFocused: true,
            selectedRoutes: [],
          },
        ]);
      } else {
        setDistributionLocations([
          ...distributionLocations.map((l) => ({
            ...l,
            isFocused: l.lat === newMarker?.geometry?.location?.lat() && l.lng === newMarker?.geometry?.location?.lng(),
          })),
        ]);
      }
      updateMap({ center: { lat, lng } });
    }
  };

  useEffect(() => {
    if (distributionLocations && distributionLocations.length) {
      setDistributionLocations(
        distributionLocations.map((location) => {
          if (location.isFocused) {
            return { ...location, radius: radius };
          } else {
            return location;
          }
        })
      );
    }
    setCircleSelectorRadius(calculateCircleRadius(radius));
    // setDrawingMode(null);
  }, [radius]);

  const isVerifiedIsLoaded = typeof isVerified === "boolean";
  const getFilteredPostCodes = (key) => [...visiblePostCodes.filter((postCode) => postCode?.name?.includes(key))];

  return (
    <Fragment>
      {isBlocked ? <BlockingOverlay /> : null}
      <Box>
        <Helmet>
          <title>Segments</title>
        </Helmet>
        <Header
          tabs={EDDM_NAVIGATION}
          activeTabIndex={1}
          headerActions={headerActions}
          hideBackButton={isSubmitted || campaignId}
          hasError={!isNextActive || isEdited}
          isDM={channel === CAMPAIGN_ENUM_TYPES.DIRECTMAIL}
        />
        <Box
          className={cn(classes.container, {
            [classes.notVerifiedContainer]: isVerifiedIsLoaded && !isVerified,
          })}
        >
          {isLoaded && !distributionLocations?.length && (
            <EDDMEmptyState
              countryCode={country?.code}
              disablePlaceAutocomplete={isSubmitted || loading}
              handleAutocompleteChanged={handleAutocompleteChanged}
              getFilteredPostCodes={getFilteredPostCodes}
            />
          )}
          <IfElse condition={isLoaded && !!distributionLocations?.length}>
            <Box>
              <Box className={cn(classes.segmentsHeaderWrapper, "mob-segments-header-wrapper")}>
                <Box className={cn(classes.searchBarWrapper, "mob-search-bar-wrapper")}>
                  <PlaceAutocomplete
                    className="mob-autocomplete"
                    countryCode={country?.code}
                    disabled={isSubmitted || loading}
                    placeholder={"Search for a specific location or address..."}
                    postCodeSearch={true}
                    onPlaceChanged={handleAutocompleteChanged}
                    getFilteredPostCodes={getFilteredPostCodes}
                  />
                  {/* <Box className={cn(classes.radiusWrapper, "mob-radius-wrapper")}>
                    <span className="display-none">Radius:</span>
                    <CustomSelect
                      options={radiusOptions}
                      selectedValue={radius}
                      onChange={(val) => {
                        setShouldAutoSave(true);
                        setRadius(val);
                      }}
                      className={cn(classes.radiusSelect, "mob-radius-select")}
                      disabled={isSubmitted || loading}
                    />
                  </Box>
                  <Box className={cn(classes.radiusWrapper, "mob-hidden")}>
                    <Saving saving={isSaving} />
                  </Box> */}
                </Box>
                <Box
                  className={cn(classes.nextBtnWrapper, "mob-next-btn mob-hidden")}
                  style={loading ? { pointerEvents: "none" } : {}}
                >
                  {!isNextActive ? (
                    <LightTooltip title={TooltipContent} placement="top">
                      <span>
                        <NextButton isNextActive={isNextActive} />
                      </span>
                    </LightTooltip>
                  ) : (
                    <NextButton
                      loading={!clickSaveButtonRef.current && isLoading}
                      isNextActive={isNextActive}
                      onClick={headerActions.NEXT.action}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            {null}
          </IfElse>

          <Box className={cn(classes.mapContainer, "mob-map-container")}>
            <IfElse condition={isLoaded}>
              <Box className={classes.mapWrapper}>
                <AudienceMapEDDM
                  isSubmitted={isSubmitted}
                  radius={radius}
                  setRadius={setRadius}
                  selectedRoutes={selectedRoutes}
                  setSelectedRoutes={setSelectedRoutes}
                  shouldAutoSave={shouldAutoSave}
                  setShouldAutoSave={setShouldAutoSave}
                  drawingMode={drawingMode}
                  setDrawingMode={setDrawingMode}
                  circleSelectorRadius={circleSelectorRadius}
                />
                {!!distributionLocations?.length && !isMobile && (
                  <EDDMToolbar
                    radius={radius}
                    setRadius={setRadius}
                    isSaving={isSaving}
                    radiusOptions={radiusOptions}
                    drawingMode={drawingMode}
                    setDrawingMode={setDrawingMode}
                    setShouldAutoSave={setShouldAutoSave}
                    circleSelectorRadius={circleSelectorRadius}
                    setCircleSelectorRadius={setCircleSelectorRadius}
                  />
                )}
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <BounceLoader />
              </Box>
            </IfElse>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
