import { formatNumber } from "utils/formators";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import cn from "classnames";
import { SettingsIcon } from "components/icons/components/Settings";
import { styles } from "./styles";
import { Accordion, AccordionTrigger, AccordionDetails } from "../../components/AddressesBlock/AddressBlock";

const useStyles = makeStyles(() => styles);

export const RouteStatistics = ({ route, selectedRoutes }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [mobRoute, setMobRoute] = useState(null);

  useEffect(() => {
    if (route) {
      setMobRoute(route);
    }
  }, [route]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* desk */}
      {route ? (
        <div className={cn(classes.statisticsBlockContainer, "mob-hidden")}>
          <>
            <div className={classes.statisticsHeaderWrapper}>
              <span>
                {route.ZIP} - {route.CROUTE} {route.ZIP_NAME}
              </span>
            </div>
            <div className={classes.statistics}>Statistics</div>
            <div className={classes.statisticsRow}>
              <span>Addresses</span> <span>{route.HOME_COUNT + route.APT_COUNT}</span>
            </div>
            {/* <div className={classes.statisticsRow}>
              <span>Apartments</span> <span>{route.APT_COUNT}</span>
            </div> */}
            <div className={classes.statisticsRow}>
              <span>Business</span> <span>{route.BUSINESS_C}</span>
            </div>
            <div className={classes.separator} />
            <div className={classes.statisticsRow}>
              <span>Total addresses (exc. businesses)</span> <span>{route.TOTAL_COUN - route.BUSINESS_C}</span>
            </div>
          </>
        </div>
      ) : null}

      {/* mob */}
      {selectedRoutes && selectedRoutes.length > 0 && mobRoute && (
        <div className="desk-hidden">
          <Accordion>
            <div
              style={{ position: "relative" }}
              className={cn("mob-statistics-block-container mob-address-container")}
            >
              <AccordionTrigger onClick={handleToggle}>
                <div className="mob-routestatistics-padding">
                  <p className="mob-route-title">Route statistics</p>
                  <KeyboardArrowDownIcon fontSize="medium" className={cn({ open: isOpen })} />
                </div>
              </AccordionTrigger>

              <AccordionDetails isOpen={isOpen}>
                {mobRoute ? (
                  <div style={{ padding: "5px 10px" }}>
                    <div className={classes.statisticsHeaderWrapper}>
                      <span>
                        {mobRoute.ZIP} - {mobRoute.CROUTE} {mobRoute.ZIP_NAME}
                      </span>
                    </div>
                    {/* <div className={classes.statistics}>Statistics</div> */}
                    <div className={classes.statisticsRow}>
                      <span>Addesses</span> <span>{formatNumber(mobRoute.HOME_COUNT + mobRoute.APT_COUNT)}</span>
                    </div>
                    {/* <div className={classes.statisticsRow}>
                      <span>Apartments</span> <span>{formatNumber(mobRoute.APT_COUNT)}</span>
                    </div> */}
                    <div className={classes.statisticsRow}>
                      <span>Business</span> <span>{formatNumber(mobRoute.BUSINESS_C)}</span>
                    </div>
                    <div className={classes.separator} />
                    <div className={classes.statisticsRow}>
                      <span>Total addresses (exc. businesses)</span>
                      <span>{formatNumber(mobRoute.TOTAL_COUN - mobRoute.BUSINESS_C)}</span>
                    </div>
                  </div>
                ) : null}
              </AccordionDetails>
            </div>
          </Accordion>
        </div>
      )}
    </>
  );
};
