export const styles = {
  toolbarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    position: "absolute",
    bottom: "27.5px",
    left: "calc(50vw - 300px)",
    // width: "543.5px",
    width: "fit-content",
    height: "78px",
    padding: "12px 16px 12px 16px",
    borderRadius: "8px",
    backgroundColor: "white",
  },
  toolbarSection: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginRight: "15px",
  },
  zoomSectionContainer: {
    width: "137px",
  },
  zoomSection: {
    width: "137px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  zoomButtons: {
    display: "flex",
    width: "85px",
  },
  zoomButton: {
    width: "41px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #E2E2FC",
    cursor: "pointer",
  },
  zoomPlus: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  zoomMinus: {
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },

  selectorContainer: {
    width: "165px",
    marginLeft: "15px",
  },
  selectionSection: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #E2E2FC",
    borderRadius: "8px",
    height: "34px",
    width: "fit-content",
  },
  selectionButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "77px",
    height: "100%",
    cursor: "pointer",
    "& span": {
      marginLeft: "10px",
    },
  },
  sizeValue: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "42px",
    height: "100%",
  },
  buttonsSeparator: {
    height: "16px",
    borderRight: "1px solid #E2E2FC",
    margin: "0px 5px",
  },
  selectedButton: {
    backgroundColor: "#1a2547",
    borderRadius: "8px",
    color: "white",
  },
  separator: {
    height: "36px",
    borderRight: "1px solid #E2E2FC",
  },
  expandIcon: {
    marginRight: "7px",
  },
  savingContainer: {
    height: "100%",
    display: "flex",
    alignItems: "end",
    paddingBottom: "5px",
  },
  disabled: {
    color: "#AEAFC7",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  sliderContainer: {
    position: "absolute",
    bottom: "51px",
    left: "320px",
    width: "184px",
    height: "62px",
    borderRadius: "8px",
    backgroundColor: "white",
    boxShadow: "0px 1px 2px 0px #0000001A",
    display: "flex",
    padding: "25px",
    alignItems: "center",
  },
};
